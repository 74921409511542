import { Controller } from "stimulus"
import { Kushki } from "@kushki/js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { merchantid: String, order: Number, total: Number, email: String, returnurl: String }

  connect() {
    this.initKushki()
  }

  initKushki() {
    this.kushki = new Kushki({
      merchantId: this.merchantidValue
    })
  }

  requestToken(evt) {
    evt.preventDefault()
    const { currentTarget } = evt
    if (currentTarget.disabled) { return }
    currentTarget.disabled = true
    const btnText = currentTarget.innerHTML
    currentTarget.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    const subscriptionId = evt.target.dataset['subscriptionId']
    this.kushki.requestDeviceToken({
      subscriptionId
    }, (response) => {
      if(response.token){
        this.checkout(response.token, subscriptionId)
      } else {
        currentTarget.disabled = false
        currentTarget.innerHTML = btnText
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message)
      }
    })
  }

  checkout(token, subscriptionId) {
    const orderId = this.orderValue
    Rails.ajax({
      type: 'POST',
      url: "/checkout/payment/subscription_charge",
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          id: orderId,
          token: token,
          subscription_id: subscriptionId
        })
        return true
      },
      error: (error) => {
        console.log('>>> kushki checkout fail:', error)
      }
    })
  }

  webpay(evt) {
    evt.preventDefault()
    const { currentTarget } = evt
    if (currentTarget.disabled) { return }
    currentTarget.disabled = true
    const btnText = currentTarget.innerHTML
    currentTarget.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    this.kushki.requestCardAsyncToken({
      totalAmount: this.totalValue,
      currency: 'CLP',
      returnUrl: this.returnurlValue, // Your return url on success
      email: this.emailValue,
      description: `Payment for Order ID: ${this.orderValue}`
    }, (response) => {
      if(response.token){
        this.checkoutWebpay(response.token)
      } else {
        currentTarget.disabled = false
        currentTarget.innerHTML = btnText
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
      }
    });
  }

  checkoutWebpay(token) {
    const orderId = this.orderValue
    Rails.ajax({
      type: 'POST',
      url: "/checkout/payment-webpay",
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          token: token,
          id: orderId
        })
        return true
      },
      error: (error) => {
        console.log('>>> kushki checkout fail:', error)
      }
    })
  }
}
