import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'details']

  handleToggleInvoiceType(evt) {
    const { value } = evt.currentTarget
    this.detailsTarget.classList.toggle('d-none', value != 'ticket')
    $('#order_invoice_razon, #order_invoice_rut, #order_invoice_address, #order_invoice_giro').prop('disabled', value != 'ticket')
  }
}
