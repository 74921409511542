import { Controller } from "stimulus"

import { decode } from "@googlemaps/polyline-codec";

export default class extends Controller {
  static targets = [ 'field', 'map', 'latitude', 'longitude', 'city', 'street' ]
  static values = { encoded: String, color: String }

  connect() {
    if (typeof google !== 'undefined') {
      this.initPlaces()
    }
  }

  initPlaces() {
    this.hasMapTarget && this.initMap()
    this.hasFieldTarget && this.initAutocomplete()
    this.hasEncodedValue && this.drawRoute()
  }

  initMap() {
    const lat = this.hasLatitudeTarget ? this.latitudeTarget.value : -33.44
    const lng = this.hasLongitudeTarget ? this.longitudeTarget.value : -70.66
    this.map = new google.maps.Map(this.mapTarget,{
      center: new google.maps.LatLng(lat, lng),
      zoom: 11,
      disableDefaultUI: true,
    })

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    this.autocomplete.setComponentRestrictions({
      country: ['cl'],
    });
    this.map && this.autocomplete.bindTo('bounds', this.map)
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace()
    if(!this.place.geometry) {
      return
    }

    this.hasLatitudeTarget && this.updateLat()
    this.hasLongitudeTarget && this.updateLng()
    this.hasMapTarget && this.updateMap()
    this.hasCityTarget && this.updateCity()
    this.hasStreetTarget && this.updateStreet()
    this.hasEncodedValue && this.drawRoute()

    if( window.location.pathname.includes('admin/orders'))
      ModalHandler.select_logistic_operator()
  }

  updateMap() {
    if(this.place.geometry.viewport) {
        this.map.fitBounds(this.place.geometry.viewport)
    } else {
      this.map.setCenter(this.place.geometry.location)
      this.map.setZoom(15)
    }

    this.marker.setPosition(this.place.geometry.location)
    this.marker.setVisible(true)
  }

  updateLat() {
    this.latitudeTarget.value = this.place.geometry.location.lat()
  }

  updateLng() {
    this.longitudeTarget.value = this.place.geometry.location.lng()
  }

  updateCity() {
    this.cityTarget.value = (this.place.address_components.find(a => a.types.includes('political'))|| {long_name: ''}).long_name
  }

  updateStreet() {
    let street_name = (this.place.address_components.find(a => a.types.includes('route')) || {long_name: ''}).long_name;
    let street_number = (this.place.address_components.find(a => a.types.includes('street_number')) || {long_name: ''}).long_name
    this.streetTarget.value = (street_name === '') ? '' : (street_name +' '+ street_number);
  }

  drawRoute() {
    const pathColor = this.colorValue || "#FF0000"
    const planCoordinates = decode(this.encodedValue, 5).map(c => new Object({ lat: c[0], lng: c[1] }));
    const path = new google.maps.Polyline({
      path: planCoordinates,
      strokeColor: pathColor,
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    path.setMap(this.map);
    this.map.setCenter(planCoordinates[0])
    this.map.setZoom(14)
  }
}
