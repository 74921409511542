import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.validateProduct()
    $(document).on('select2:select', () => this.validateProduct())
  }

  validateProduct() {
    const validStockLocation = Array.prototype.slice.call(document.getElementsByName('product[default_stock_locations][]')).some(el => el.checked)
    const validShippingType = document.getElementById('product_shipping_category_id').value.length > 0
    document.querySelector('button[type="submit"]').disabled = !validStockLocation || !validShippingType
  }
}