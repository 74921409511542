import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'submit' ]

  connect() {}

  add() {
    setTimeout(() => {
      this.submitTarget.disabled = (document.getElementById('transfer_variants_tbody').childElementCount == 0)
    }, 500);
  }
}
