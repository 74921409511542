ScheduleHandler = {};
let schedule = [
    '09:00-11:00',
    '11:00-13:00',
    '13:00-15:00',
    '15:00-17:00',
    '17:00-19:00',
    '19:00-20:00'
]

ScheduleHandler.setSchedule = (stock_location_id, schedules) => {
  ScheduleHandler.calc_start_and_end_date(schedules)
  let availableWeekdays = ScheduleHandler.currentWeekdays(schedules);
  let firstDay = availableWeekdays[0];
  let lastDay = availableWeekdays[1];
  let current_date = moment(moment().format());
  let init_modal_called = false

  ScheduleHandler.setScheduleDayPickup(firstDay, lastDay, stock_location_id);
  for(let i = 0; i < schedules.length; i++) {
    if (schedules[i].day_code === firstDay.day_code) {
      ScheduleHandler.setScheduleCardModal(firstDay, $('[data-schedule-today-' + stock_location_id + ']'), stock_location_id, 'first');
      if (moment(firstDay.calc_start_date).isBefore(moment()) && moment(firstDay.calc_end_date).isAfter(moment())) {
        $('[data-schedule-default-' + stock_location_id + ']').show();
        ScheduleHandler.initModal(stock_location_id, firstDay.calc_start_date, firstDay.calc_end_date);
        init_modal_called = true
      }
    } else if (schedules[i].day_code === lastDay.day_code) {
      ScheduleHandler.setScheduleCardModal(lastDay, $('[data-schedule-tomorrow-' + stock_location_id + ']'), stock_location_id, 'last');
      if (!init_modal_called) {
        ScheduleHandler.initModal(stock_location_id, lastDay.calc_start_date, lastDay.calc_end_datem);
      }
    }
  }
}

ScheduleHandler.calc_start_and_end_date = function (schedules) {
  const today = moment();

  schedules.forEach((schedule) => {
    let hour = moment(schedule.starts_at).hour()
    let minute = moment(schedule.starts_at).minute()
    let end_hour = moment(schedule.ends_at).hour()
    let end_minute = moment(schedule.ends_at).minute()

    schedule.calc_start_date = today.set({hour: hour, minute: minute}).format(),
    schedule.calc_end_date = today.set({hour: end_hour, minute: end_minute}).format()
  })
}

ScheduleHandler.currentWeekdays = function (schedules) {
  const today = moment();
  const diaActual = today.weekday();
  let diaSiguiente = diaActual;
  let diasAbiertos = [];
  let current_date = moment(moment().format());

  if (!schedules[diaActual].closed && moment().weekday() === schedules[diaActual].day_code) {
    if ((current_date.hour() >= 0 && current_date < moment(schedules[diaActual].calc_start_date)) || ((current_date >= moment(schedules[diaActual].calc_start_date)) && (current_date < moment(schedules[diaActual].calc_end_date)))) {
      diasAbiertos.push( schedules[diaActual] );
    }
  }

  while (diasAbiertos.length < 2) {
    diaSiguiente = (diaSiguiente + 1) % 7; // Avanzar al siguiente día de la semana

    // Si ha vuelto al día actual, significa que todos los días están cerrados, salir del ciclo
    if (diaSiguiente === diaActual) {
      console.log('Todos los días están cerrados esta semana.');
      break;
    }

    if (!schedules[diaSiguiente].closed) {
      let calcDate;

      if (diaSiguiente <= diaActual) { // Si el próximo día abierto es en la próxima semana
        calcDate = moment().add(1, 'week').day(diaSiguiente);
      } else { // Si el próximo día abierto es en esta semana
        calcDate = moment().day(diaSiguiente);
      }

      let calc_start_date = calcDate.clone().set({
        hour: moment(schedules[diaSiguiente].starts_at).hour(),
        minute: moment(schedules[diaSiguiente].starts_at).minute(),
      }).format();

      let calc_end_date = calcDate.clone().set({
        hour: moment(schedules[diaSiguiente].ends_at).hour(),
        minute: moment(schedules[diaSiguiente].ends_at).minute(),
      }).format();

      // Si ends_at no es antes del momento actual, agregar al array
      if (moment(calc_end_date).isAfter(moment())) {
        diasAbiertos.push({
          ...schedules[diaSiguiente],
          calc_start_date: calc_start_date,
          calc_end_date: calc_end_date
        });
      }
    }
  }
  return diasAbiertos;
}

ScheduleHandler.setScheduleCardModal = function (schedule, $selector, stock_location_id, day) {
  let schedule_starts = new Date(schedule.starts_at)
  let schedule_ends = new Date(schedule.ends_at)
  let schedule_starts_at = schedule_starts.getHours()
  let schedule_ends_at = schedule_ends.getHours()
  let loops = Math.round((schedule_ends_at - schedule_starts_at) / 2);
  for(let i = 0; i < loops; i++) {
    let end_hour = schedule_starts_at + 2
    if (i === loops - 1 && (schedule_ends_at - schedule_starts_at > 1)) end_hour += ((schedule_ends_at - schedule_starts_at) % i)
    if (end_hour > schedule_ends_at) end_hour = schedule_ends_at
    let firstHour = moment().set({
      year: moment(schedule.calc_start_date).year(),
      month: moment(schedule.calc_start_date).month(),
      date: moment(schedule.calc_start_date).date(),
      hour: schedule_starts_at,
      minute: (i === 0) ? schedule_starts.getMinutes() : 0
    });
    let lastHour = moment().set({
      year: moment(schedule.calc_end_date).year(),
      month: moment(schedule.calc_end_date).month(),
      date: moment(schedule.calc_end_date).date(),
      hour: end_hour,
      minute: ((loops-1) === i) ? schedule_ends.getMinutes() : 0
    });
    
    if(!lastHour.isBefore(moment())) {
      ScheduleHandler.createElements(firstHour, lastHour, $selector, stock_location_id, day);
    }
    
    schedule_starts_at += 2;
  }

  const $el = $('#pills-first-' + stock_location_id).find('[data-schedule-' + stock_location_id + ']').filter(function() {
    return $(this).css("display") !== "none";
  }).first();

  const scheduleValue = $el.attr('data-schedule-' + stock_location_id);
  const message = $el.attr('data-message-' + stock_location_id);
  $('[data-set-schedule-' + stock_location_id + ']').html(message);
  $('[schedule-sl-id-' + stock_location_id + ']').val(scheduleValue);
  $('[data-schedule-sl-id-' + stock_location_id + ']').val(scheduleValue);
  $('[data-schedule-today-' + stock_location_id + '] button').removeClass('active');
  $el.addClass('active');
}

ScheduleHandler.createElements = function (first, last, $selector, stock_location_id, day) {
  let dayMode = first.format('DD-MM-YYYY');
  let show_pronoun = true;

  if (moment().format('DD-MM-YYYY') === first.format('DD-MM-YYYY') ) {
    dayMode = 'Hoy';
    show_pronoun = false;
  }
  else if (moment().add(1,'days').format('DD-MM-YYYY') === first.format('DD-MM-YYYY') ) {
    dayMode = 'Mañana';
    show_pronoun = false;
  }

  $('#pills-' + day + '-' + stock_location_id + '-tab').text(dayMode);

  let text_day = show_pronoun === true ? 'El ' : '';
  $('#schedule-' + day + '-day-pickup-' + stock_location_id).text(text_day + dayMode);

  $selector.append(`
    <button type="button" data-message-${stock_location_id}="Programado ${dayMode}: ${first.format('HH:mm')} - ${last.format('HH:mm')}" data-schedule-${stock_location_id}="${first.format('YYYY-MM-DD HH:mm:ss -0400')}" class="btn btn-light white w-100">
      <span class="material-symbols-outlined icon">radio_button_unchecked</span> ${first.format('HH:mm')} - ${last.format('HH:mm')}
    </button>
  `);
};

ScheduleHandler.initModal = function (stock_location_id, schedule_starts_at, schedule_ends_at) {
  if(window.location.href.includes('checkout')) {
    $(document).on('click', '[data-schedule-' + stock_location_id + ']', function () {
      $('[data-schedule-' + stock_location_id + ']').removeClass('active');
      $(this).addClass('active');
      $('[data-schedule-' + stock_location_id + ']').find('.icon').text('radio_button_unchecked');
      $(this).find('.icon').text('radio_button_checked');
    });

    $(document).on('click', '[data-save-schedule-' + stock_location_id + ']', function () {
      let shipment_id = $(this).attr('data-shipment-id');
      let shipping_rate_id = $(this).attr('data-shipping-rate-id');
      const scheduleValue = $('[data-schedule-' + stock_location_id + '].active').attr('data-schedule-' + stock_location_id);
      const message = $('[data-schedule-' + stock_location_id + '].active').attr('data-message-' + stock_location_id);
      $('[data-set-schedule-' + stock_location_id + ']').html(message);
      $('[schedule-sl-id-' + stock_location_id + ']').val(scheduleValue);
      $('[data-schedule-sl-id-' + stock_location_id + ']').val(scheduleValue);
      ScheduleHandler.updateShipment(shipment_id, scheduleValue, null, null, null, shipping_rate_id);
    });

    $(document).on('click', '[data-save-pickup-' + stock_location_id + ']', function () {
      let shipment_id = $(this).attr('data-shipment-id');
      let shipping_rate_id = $(this).attr('data-shipping-rate-id');
      let first_name = $('#shipment_' + shipment_id + '_withdrawal_first_name').val();
      let last_name = $('#shipment_' + shipment_id + '_withdrawal_last_name').val();
      let phone = $('#shipment_' + shipment_id + '_withdrawal_phone').val();
      let pickup_form_valid = ScheduleHandler.validatePickupForm(shipment_id, first_name, last_name, phone);

      if(pickup_form_valid === true) {
        $('#screen-loader').show();
        ScheduleHandler.switchBtn(true);
        ScheduleHandler.setPickupForms(first_name, last_name, phone)
        ScheduleHandler.updateShipment(shipment_id, null, first_name, last_name, phone, shipping_rate_id);
      }
    });
  }
};

ScheduleHandler.setPickupForms = function (first_name, last_name, phone) {
  const input_classes = ['withdrawal_first_name', 'withdrawal_last_name', 'withdrawal_phone'];

  $.each( input_classes, function( i, class_name ) {
    $('.'+class_name).each(function ( index, input ) {
      if(input.value === null || input.value === "") {
        switch(class_name) {
          case 'withdrawal_first_name':
            input.value = first_name;
            break;
          case 'withdrawal_last_name':
            input.value = last_name;
            break;
          case 'withdrawal_phone':
            input.value = phone;
            break;
        }
      }
    })
  })
}

ScheduleHandler.validatePickupForm = function (shipment_id, first_name, last_name, phone) {
  let error = false
  $('#content-alert-pickup-form-'+shipment_id).hide();

  if(shipment_id === '' || first_name === '' || phone === '') {
    $('#alert-msg-pickup-form-'+shipment_id).text('Campos requeridos');
    error = true;
  } else if(phone.length != 8) {
    $('#alert-msg-pickup-form-'+shipment_id).text('Ingresa 8 números en el campo teléfono');
    error = true;
  }

  if(error === true) {
    $('#content-alert-pickup-form-'+shipment_id).show()
    if($('#pickupModal-'+shipment_id).hasClass('show') === false) {
      $('#pickupModal-'+shipment_id).modal('show');
    }
  } else {
    $('#pickupModal-'+shipment_id).modal('hide');
  }
  return !error;
}

ScheduleHandler.setScheduleDayPickup = function (firstDay, lastDay, stock_location_id) {
  let current_date = moment(moment().format());
  let first_day_text = 'de ' + moment(firstDay.calc_start_date).format('HH:mm') + ' a ' + moment(firstDay.calc_end_date).format('HH:mm')
  let last_day_text = 'de ' + moment(lastDay.calc_start_date).format('HH:mm') + ' a ' + moment(lastDay.calc_end_date).format('HH:mm')

  if ((current_date.hour() >= 0 && current_date < moment(firstDay.calc_start_date)) || ((current_date >= moment(firstDay.calc_start_date)) && (current_date < moment(firstDay.calc_end_date)))) {
    $('#schedule-first-hour-pickup-' + stock_location_id).text(first_day_text);
    $('#li-schedule-first-day-pickup-' + stock_location_id).show();
    $('#li-schedule-last-day-pickup-' + stock_location_id).hide();
  } else {
    $('#schedule-last-hour-pickup-' + stock_location_id).text(last_day_text);
    $('#li-schedule-last-day-pickup-' + stock_location_id).show();
    $('#li-schedule-first-day-pickup-' + stock_location_id).hide();
  }
}

ScheduleHandler.activeBtnCheckout = function (btn_type, stock_location_id) {
  if( btn_type === 'schedule') {
    $('[data-set-schedule-' + stock_location_id + ']').removeClass('btn-shipment-inactive');
    $('[data-set-schedule-' + stock_location_id + ']').addClass('btn-shipment-active');
    $('[data-set-pickup-' + stock_location_id + ']').removeClass('btn-shipment-active');
    $('[data-set-pickup-' + stock_location_id + ']').addClass('btn-shipment-inactive');
  } else if ( btn_type === 'pickup') {
    $('[data-set-schedule-' + stock_location_id + ']').removeClass('btn-shipment-active');
    $('[data-set-schedule-' + stock_location_id + ']').addClass('btn-shipment-inactive');
    $('[data-set-pickup-' + stock_location_id + ']').removeClass('btn-shipment-inactive');
    $('[data-set-pickup-' + stock_location_id + ']').addClass('btn-shipment-active');
  }
}

ScheduleHandler.updateShipment = function (shipment_id, schedule_value, first_name, last_name, phone, shipping_rate_id) {
  ScheduleHandler.switchBtn(true);
  const order_token = $('#order_token').val();
  return $.ajax({
    url: Spree.pathFor('api/v2/storefront/shipments/' + shipment_id),
    method: 'PATCH',
    headers: 'X-Spree-Order-Token: '+ order_token,
    data: {
      shipment: {
        scheduled_at: schedule_value,
        withdrawal_first_name: first_name,
        withdrawal_last_name: last_name,
        withdrawal_phone: phone,
        shipping_rate_id: shipping_rate_id
      }
    }
  }).done(function (resp) {
    let btn_type = 'schedule';
    let stock_location_id = resp.data.relationships.stock_location.data.id;
    let shipment_id = resp.data.id;
    let shipment_price = resp.data.attributes.final_price <= 0 ? '$0' : resp.data.attributes.display_final_price;

    if(resp.data.attributes.pickup === true) {
      btn_type = 'pickup';
      $('#pickupModal-'+stock_location_id).modal('hide');
    } else {
      $('#scheduleModal-'+stock_location_id).modal('hide');
    }

    $('#shipment-'+ shipment_id +'-card-total').text(shipment_price)
    ScheduleHandler.updateOrderValuesInFront()
    ScheduleHandler.activeBtnCheckout(btn_type, stock_location_id);
    $('#screen-loader').hide();
    ScheduleHandler.switchBtn(false);
  })
}

ScheduleHandler.updateOrderValuesInFront = function () {
  let order_token = $('#order_token').val();
  const settings = {
    async: true,
    crossDomain: true,
    url: Spree.pathFor('api/v2/storefront/cart'),
    method: 'GET',
    headers: {
      Accept: 'application/vnd.api+json',
      'X-Spree-Order-Token': order_token
    }
  };

  $.ajax(settings).done(function (resp) {
    window.paymentBrickController.unmount();
    show_mercado_pago(resp.data.attributes.total);
    $('#checkout_order_subtotal').text(resp.data.attributes.display_item_total);
    $('#shipping_and_service_total').length != 0
      $('#shipping_and_service_total').text(resp.data.attributes.display_adjustment_total)
    $('#shipping_and_service_total').length != 0
      $('#shipping_and_service_total').text(resp.data.attributes.display_ship_total);
    $('#order_adjustments_total').length != 0
      $('#order_adjustments_total').text(resp.data.attributes.display_adjustment_total);
    $('#card_order_total').text(resp.data.attributes.display_total);
    $('#order_total').text(resp.data.attributes.display_total);
    $('#order_mp_total').text(resp.data.attributes.display_total);
    $('#screen-loader').hide();
    ScheduleHandler.switchBtn(false);
  });
}

ScheduleHandler.switchBtn = (condition) => {
  var handleBtn = document.querySelectorAll('.btn');
  for (var i = 0; i < handleBtn.length; i++) {
    if (condition) {
      handleBtn.item(i).setAttribute('disabled', condition);
      handleBtn.item(i).classList.add('block-btn');
    } else {
      handleBtn.item(i).removeAttribute('disabled');
      handleBtn.item(i).classList.remove('block-btn');
    }
  }
  setTimeout(() => {
  }, 3000)
};

