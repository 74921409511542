import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'list', 'new', 'data', 'delete' ]
  administrate = false

  showNew(evt) {
    evt.preventDefault()
    this.newTarget.classList.remove('d-none')
    this.listTarget.classList.add('d-none')
    scroll(0, 0)
  }

  hiddeNew(evt) {
    evt.preventDefault()
    this.newTarget.classList.add('d-none')
    this.listTarget.classList.remove('d-none')
    scroll(0, 0)
  }

  toggleDelete(evt) {
    evt.preventDefault()
    this.administrate = !this.administrate
    if (this.administrate) {
      evt.currentTarget.text = 'Guardar'
      this.deleteTargets.forEach(t => t.classList.remove('d-none'))
      this.dataTargets.forEach(t => t.classList.add('move'))
    } else {
      evt.currentTarget.text = 'Administrar'
      this.deleteTargets.forEach(t => t.classList.add('d-none'))
      this.dataTargets.forEach(t => t.classList.remove('move'))
    }
  }

  setDefault(evt) {
    const id = evt.target.dataset.cardId
    evt.target.insertAdjacentHTML('afterend', "<i class='fa fa-spinner fa-spin'></i>")
    evt.target.classList.add('d-none')
    Rails.ajax({
      type: 'PUT',
      url: `/account/cards/${id}`,
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          default: true,
        })
        return true
      },
      error: (response) => {
        this.enableSubmit()
        if(this.submitTarget.nextElementSibling === null) {
          this.submitTarget.insertAdjacentHTML('afterend', `<p class="alert-danger">${response.error}</p>`)
        }
      }
    })
  }
}
