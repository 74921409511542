import { Controller } from "stimulus"
import { Kushki } from "@kushki/js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form', 'submit' ]
  static values = { merchantid: String, email: String, callbackurl: String }

  connect() {
    this.initKushki()
  }

  initKushki() {
    this.kushki = new Kushki({
      merchantId: this.merchantidValue
    });
  }

  addDebit(evt) {
    evt.preventDefault()
    this.disableSubmit()
    this.kushki.requestSubscriptionCardAsyncToken({
      currency: "CLP",
      email: this.emailValue,
      callbackUrl: this.callbackurlValue // URL a la cual retornará el usuario completado el proceso de inscripción
    }, (response) => {
      if(response.token){
        this.registerCardAsync(response.token)
      } else {
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
        this.enableSubmit()
        if(this.submitTarget.nextElementSibling === null) {
          this.submitTarget.insertAdjacentHTML('afterend', `<p class="alert-danger">${response.message}</p>`)
        }
      }
    });
  }

  registerCardAsync(token) {
    Rails.ajax({
      type: 'POST',
      url: "/account/async_cards",
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          token: token,
          async: true,
          card: {
            cc_type: 'debit',
            kushki_token: token
          }
        })
        return true
      },
      success: (data) => {
        console.log(data)
        this.enableSubmit()
      },
      error: (response) => {
        this.enableSubmit()
        if(this.submitTarget.nextElementSibling === null) {
          this.submitTarget.insertAdjacentHTML('afterend', `<p class="alert-danger">${response.error}</p>`)
        }
      }
    })
  }

  disableSubmit() {
    this.submitTarget.disabled = true
    this.btnText = this.submitTarget.innerHTML
    this.submitTarget.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    if(this.submitTarget.nextElementSibling !== null) {
      this.submitTarget.nextElementSibling.remove()
    }
  }

  enableSubmit() {
    this.submitTarget.disabled = false
    this.submitTarget.innerHTML = this.btnText
  }
}