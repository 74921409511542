import { Controller } from 'stimulus'
import { Kushki } from "@kushki/js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form', 'details', 'paymentMethods', 'methodIcon', 'methodTitle',
                     'methodDescription', 'select', 'selected', 'paymentMethodId',
                     'kushki', 'paymentSourcePMId', 'paymentSourceOId',
                     'paymentSourceSId', 'paymentSourceCCId', 'paymentSourceToken',
                     'latamCodeForm', 'latamCode' ]
  static values = { merchantid: String, order: Number, total: Number, email: String, returnurl: String, default: String }

  connect() {
    this.initKushki()
  }

  initKushki() {
    this.kushki = new Kushki({
      merchantId: this.merchantidValue
    })
  }

  onSubmit(evt){
    const paymentSource = this.defaultValue
    evt.preventDefault()
    if (!this.formTarget.checkValidity()) {
      $('input:invalid').css({ "border": '#FF0000 1px solid' })
      $('input:invalid')[0].focus()
      return
    }
    if (this.paymentMethodIdTarget.value === '') {
      this.showPaymentMethods(evt)
    } else {
      evt.currentTarget.innerHTML = "<i class='fas fa-circle-notch fa-spin'></i>"
      if (paymentSource === "Spree::Gateway::KushkiWebpay" && this.paymentSourceTokenTarget.value === '') {
        this.kushki.requestCardAsyncToken({
          totalAmount: this.totalValue,
          currency: 'CLP',
          returnUrl: this.returnurlValue, // Your return url on success
          email: this.emailValue,
          description: `Payment for Order ID: ${this.orderValue}`
        }, (response) => {
          if (response.token) {
            this.paymentSourceTokenTarget.value = response.token
            this.formTarget.submit()
          } else {
            console.error('Error: ', response.error, 'Code: ', response.code, 'Message: ', response.message);
          }
        });
      } else if (paymentSource === "Spree::Gateway::KushkiCreditCard" && this.paymentSourceTokenTarget.value === '') {
        const subscriptionId = this.paymentSourceSIdTarget.value
        this.kushki.requestDeviceToken({
          subscriptionId
        }, (response) => {
          if(response.token){
            this.paymentSourceTokenTarget.value = response.token
            this.formTarget.submit()
          } else {
            console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message)
          }
        })
      } else {
        this.formTarget.submit()
      }
      setTimeout(() => document.getElementById('checkout-loader').classList.remove('d-none'), 1000);
    }
  }

  showPaymentMethods(evt) {
    evt.preventDefault()
    this.paymentMethodsTarget.classList.remove('d-none')
    scroll(0, 0)
  }

  hiddePaymentMethods(evt) {
    evt.preventDefault()
    this.paymentMethodsTarget.classList.add('d-none')
    scroll(0, 0)
  }

  showSelectedPaymentMethods() {
    this.hasSelectTarget && this.selectTarget.classList.add('d-none')
    this.hasSelectedTarget && this.selectedTarget.classList.remove('d-none')
  }

  selectPaymentMethod(evt) {
    const { value, dataset } = evt.target
    const { type, title, description, icon, card, subscription } = dataset
    this.paymentSourceType = type
    this.showSelectedPaymentMethods()
    this.methodTitleTarget.innerHTML = title
    this.methodDescriptionTarget.innerHTML = description
    this.methodIconTarget.srcset = icon

    this.paymentSourcePMIdTarget.name = `payment_source[${value}][name]`
    this.paymentSourceOIdTarget.name = `payment_source[${value}][order_id]`
    this.paymentSourceCCIdTarget.name = `payment_source[${value}][credit_card_id]`
    this.paymentSourceSIdTarget.name = `payment_source[${value}][subscription_id]`
    this.paymentSourceTokenTarget.name = `payment_source[${value}][token]`

    this.paymentMethodIdTarget.value = value;
    if (card !== undefined) {
      this.paymentSourceCCIdTarget.value = card;
    } else {
      this.paymentSourceCCIdTarget.name = null
    }
    if (subscription !== undefined) {
      this.paymentSourceSIdTarget.value = subscription;
    } else {
      this.paymentSourceSIdTarget.name = null
    }

    this.kushkiTarget.classList.add('d-none')

    if(type === "Spree::Gateway::KushkiWebpay") {
      this.getWebpayToken()
      this.kushkiTarget.classList.remove('d-none')
    }

    if(type === "Spree::Gateway::KushkiCreditCard") {
      this.getDeviceToken()
      this.kushkiTarget.classList.remove('d-none')
    }
  }

  showLatamCodeForm(evt) {
    evt.preventDefault()
    this.latamCodeFormTarget.classList.remove('d-none')
    scroll(0, 0)
  }

  hideLatamCodeForm(evt) {
    evt.preventDefault()
    this.latamCodeFormTarget.classList.add('d-none')
    scroll(0, 0)
  }

  removeLatamCode(evt) {
    evt.preventDefault()
    const { url } = this.latamCodeFormTarget.dataset

    Rails.ajax({
      type: 'PATCH',
      url,
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        xhr.setRequestHeader('Authorization', `Bearer ${SpreeAPI.oauthToken}`),
        options.data = JSON.stringify({
          order: {
            latam_code: ""
          }
        })
        return true
      },
      success: () => {
        window.location.reload(true)
      }
    })
  }

  applyLatamCode() {
    if (typeof $ != 'undefined') {
      const latamCode = $('#latam_code').val();
      if (latamCode && latamCode != '') {
        $('#latam_code_active').show();
        $('#latam_code_active [data-latam-code]').text(latamCode);
        $('[name="order[latam_code]"]').val(latamCode);
        $('#latam_code_empty').hide();
      } else {
        $('#latam_code_active').hide();
        $('#latam_code_active [data-latam-code]').text('');
        $('[name="order[latam_code]"]').val('');
        $('#latam_code_empty').show();
      }
      $('#latam-code-form').addClass('d-none');
    }
  }

  getWebpayToken() {
    this.kushki.requestCardAsyncToken({
      totalAmount: this.totalValue,
      currency: 'CLP',
      returnUrl: this.returnurlValue, // Your return url on success
      email: this.emailValue,
      description: `Payment for Order ID: ${this.orderValue}`
    }, (response) => {
      if(response.token){
        this.paymentSourceTokenTarget.value = response.token
      } else {
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
      }
    });
  }

  getDeviceToken() {
    const subscriptionId = this.paymentSourceSIdTarget.value
    this.kushki.requestDeviceToken({
      subscriptionId
    }, (response) => {
      if(response.token){
        this.paymentSourceTokenTarget.value = response.token
      } else {
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message)
      }
    })
  }
}
if (typeof $ != 'undefined') {
  document.addEventListener('turbolinks:load', function () {
    $('#delete_latam_code').click(function () {
      $('#latam_code_active').hide();
      $('#latam_code_active [data-latam-code]').text('');
      $('#latam_code_empty').show();
      $('#latam_code').val('');
      $('[name="order[latam_code]"]').val('');
    })
  })

  $(document).ready(function () {
    $(window).keydown(function (event) {
      if ($('#checkout-page').length > 0) {
        if (event.keyCode == 13) {
          event.preventDefault();
          const latamCode = $('#latam_code').val();
          if (latamCode && latamCode != '') {
            $('#latam_code_active').show();
            $('#latam_code_active [data-latam-code]').text(latamCode);
            $('[name="order[latam_code]"]').val(latamCode);
            $('#latam_code_empty').hide();
          } else {
            $('#latam_code_active').hide();
            $('#latam_code_active [data-latam-code]').text('');
            $('[name="order[latam_code]"]').val('');
            $('#latam_code_empty').show();
          }
          $('#latam-code-form').addClass('d-none');
          return false;
        }
      }
    });
  });
}
