import { Controller } from "stimulus"
import { Kushki } from "@kushki/js"
import * as Card from "card"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form', 'submit', 'otp', 'submitOTP', 'otpError', 'cardForm' ]
  static values = { merchantid: String, order: Number }
  retries = 0

  connect() {
    this.initCard()
    this.initKushki()
    this.checkValidity()
  }

  initKushki() {
    this.kushki = new Kushki({
      merchantId: this.merchantidValue
    });
  }

  initCard() {
    this.card = new Card({
      form: 'form#example',
      container: '.card-wrapper',
      placeholders: {
          number: '**** **** **** ****',
          name: 'Nombre',
          expiry: '**/****',
          cvc: '***'
      },
      messages: {
          validDate: 'HASTA\nTRHU',
          monthYear: 'mm/yyyy'
      }
    });
  }

  subscribeCard(evt){
    evt.preventDefault()
    if (this.invalidForm || this.submitTarget.disabled) { return }
    this.disableSubmit()
    const form = new FormData(this.formTarget)
    const [mm, yy] = form.get('expiry').split('/')
    const card = {
      name: form.get('name').trim(),
      number: form.get('number').replace(/\s+/g, ''),
      cvc: form.get('cvc').trim(),
      expiryMonth: mm.trim(),
      expiryYear: yy.trim().slice(-2),
    }
    this.kushki.requestSubscriptionToken({
      currency: "CLP",
      card,
    }, (response) => {
      if(response.token){
        this.response = response
        this.cardData = {
          name: card.name,
          number: card.number.slice(-4),
          provider: this.card.cardType,
          month: card.expiryMonth,
          year: card.expiryYear,
        }
        if(response.secureId) {
          this.showOTP = true
          this.otpTarget.classList.remove('d-none')
          this.formTarget.classList.add('d-none')
        } else {
          this.registerCard()
        }
      } else {
        this.enableSubmit()
        if(this.submitTarget.nextElementSibling === null) {
          this.submitTarget.insertAdjacentHTML('afterend', `<p class="alert-danger mt-2 p-2" style="border-radius: 15px">${response.message}</p>`)
        }
      }
    });
  }

  validate(evt) {
    const { target } = evt
    if (target.value !== '') {
      if(target.nextElementSibling !== null) {
        target.nextElementSibling.remove()
      }
      if(target.classList.contains('jp-card-invalid')) {
        target.insertAdjacentHTML('afterend', '<p class="alert-danger mt-2 p-2" style="border-radius: 15px"> Dato inválido</p>')
      }
    } else {
      if(target.nextElementSibling === null) {
        target.insertAdjacentHTML('afterend', '<p class="alert-danger mt-2 p-2" style="border-radius: 15px"> * Campo obligatorio</p>')
      }
    }

    this.checkValidity()
  }

  validateOTP(evt) {
    evt.preventDefault()
    this.submitOTPTarget.disabled = true
    this.submitOTPTarget.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    if(this.submitOTPTarget.nextElementSibling !== null) {
      this.submitOTPTarget.nextElementSibling.remove()
    }
    const form = new FormData(evt.currentTarget)
    this.retries = this.retries + 1
    this.kushki.requestSecureServiceValidation({
     secureServiceId: this.response.secureId,
     otpValue: form.get('otpnumber').replace(/\s+/g, '')
    }, (response) => {
      if(response.code === 'OTP000'){
        this.registerCard()
      } else if (response.message == 'Intentos excedidos') {
        this.showOTPError()
      } else {
        if(this.submitOTPTarget.nextElementSibling === null) {
          this.submitOTPTarget.insertAdjacentHTML('afterend', `<p class="alert-danger mt-2 p-2" style="border-radius: 15px">Monto invalido. Vuelve a ingresarlo (${this.retries}/3 intentos)</p>`)
        }
        this.submitOTPTarget.disabled = false
        this.submitOTPTarget.innerHTML = "Validar"
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
      }
    });
  }

  checkValidity() {
    this.invalidForm = !this.formTarget.checkValidity() || document.querySelectorAll('input.jp-card-invalid').length > 0
    this.submitTarget.disabled = this.invalidForm
  }

  registerCard() {
    const orderId = this.orderValue
    Rails.ajax({
      type: 'POST',
      url: "/account/cards",
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          order_id: orderId,
          token: this.response.token,
          card: {
              provider: this.cardData.provider,
              cc_type: 'credit',
              name: this.cardData.name,
              last_digits: this.cardData.number,
              month: this.cardData.month,
              year: this.cardData.year,
              kushki_token: this.response.token
          }
        })
        return true
      },
      success: (data) => {
        window.location.reload(true)
      },
      error: (response) => {
        this.enableSubmit()
        if(this.submitTarget.nextElementSibling === null) {
          this.submitTarget.insertAdjacentHTML('afterend', `<p class="alert-danger mt-2 p-2" style="border-radius: 15px">${response.error}</p>`)
        }
      }
    })
  }

  disableSubmit() {
    this.submitTarget.disabled = true
    this.btnText = this.submitTarget.innerHTML
    this.submitTarget.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    if(this.submitTarget.nextElementSibling !== null) {
      this.submitTarget.nextElementSibling.remove()
    }
  }

  enableSubmit() {
    this.submitTarget.disabled = false
    this.submitTarget.innerHTML = this.btnText
  }

  showOTPError() {
    this.cardFormTarget.classList.add('d-none')
    this.otpErrorTarget.classList.remove('d-none')
  }
}
