import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'details', 'success']
  connect() {}

  showDetails(evt) {
    evt.preventDefault()
    this.detailsTarget.classList.remove('d-none')
    this.successTarget.classList.remove('d-flex')
    this.successTarget.classList.add('d-none')
    scroll(0, 0)
  }
}