import { Controller } from "stimulus"
import Rails from "@rails/ujs"

import { JOURNEY_STATES } from '../packs/constants/journey_states'

export default class extends Controller {
  static targets = [ 'state' ]
  static values = { id: Number, shipment: String, initial: String }

  connect() {
    this.state_name = this.initialValue
    this.state_name && this.updateBadge()

    if(JOURNEY_STATES[this.initialValue].is_final) {
      return;
    }
    this.idValue && this.initJourney();
  }

  disconnect() {
    this.timeInterval && clearInterval(this.timeInterval);
  }

  initJourney() {
    this.timeInterval = setInterval(this.getJourneyState.bind(this), 15000);
  }

  getJourneyState() {
    Rails.ajax({
      type: 'GET',
      url: Spree.adminPathFor('orders/get_journey_cabify'),
      data: `shipment_number=${this.shipmentValue}&journey_id=${this.idValue}`,
      dataType: 'json',
      success: (response) => {
        const { data } = response.data
        this.handleSuccess(data.journey)
      },
      error: (error) => {
        console.log('>>> getJourneyState fail:', error)
      },
    })
  }

  handleSuccess(journey) {
    if(journey === null) return true

    const waypoints = journey.state.waypoints
    this.state_name = waypoints.rows[waypoints.rows.length - 1].stateName.toLowerCase()

    this.updateLabel(journey)
    this.updateBadge()
    if(JOURNEY_STATES[this.state_name].is_final) {
      
      this.timeInterval && clearInterval(this.timeInterval);
    }
    this.updateJourney(journey)
  }

  updateJourney = (journey) => {
    Rails.ajax({
      type: 'PUT',
      url: Spree.adminPathFor('orders/update_journey'),
      dataType: "json",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader('Content-Type', 'application/json'),
        options.data = JSON.stringify({
          shipment_number: this.shipmentValue,
          origin_call: 'index',
          journey: {
            id: this.idValue,
            ...journey
          },
        })
        return true
      },
      success: (response) => {
        console.log('Journey actualizaaado')
        console.log(response)
      },
      error: (error) => {
        console.log('>>> updateJourney fail:', error)
      },
    })
  }

  updateLabel(journey) {
    let current_state = JOURNEY_STATES[this.state_name].label
    if (this.state_name == 'drop off') {
      const end_at = new Date(journey.endAt).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
      current_state += ` ${end_at}`
    }
    this.stateTarget.innerHTML = current_state
  }

  updateBadge() {
    this.stateTarget.className = `badge badge-${JOURNEY_STATES[this.state_name].color}`
  }
}
