export const JOURNEY_STATES = {
  'estimated': {
    label: 'Pendiente',
    is_final: false,
    color: 'info'
  },
  'hire': {
    label: 'Pendiente',
    is_final: false,
    color: 'info'
  },
  'hired': {
    label: 'En camino',
    is_final: false,
    color: 'info'
  },
  'arrived': {
    label: 'Ha llegado a tienda',
    is_final: false,
    color: 'info'
  },
  'pick up': {
    label: 'En camino',
    is_final: false,
    color: 'info'
  },
  'drop off': {
    label: 'Finalizado',
    is_final: true,
    color: 'success'
  },
  'finish': {
    label: 'Finalizado',
    is_final: true,
    color: 'success'
  },
  'finished': {
    label: 'Finalizado',
    is_final: true,
    color: 'success'
  },
  'terminated': {
    label: 'Finalizado',
    is_final: true,
    color: 'success'
  },
  'not found': {
    label: 'Finalizado', //'Sin conductor',
    is_final: true,
    color: 'warning'
  },
  'unknown': {
    label: 'Conectando...',
    is_final: false,
    color: 'info'
  },
  'rider cancel': {
    label: 'Finalizado', //'Cancelado por usuario',
    is_final: true,
    color: 'danger'
  },
  'no show': {
    label: 'Finalizado', //'Conductor no te ha visto',
    is_final: true,
    color: 'warning'
  },
  'driver cancel': {
    label: 'Finalizado', //'Cancelado por conductor',
    is_final: true,
    color: 'danger'
  },
  'withdrawal pending': {
    label: 'Pendiente de retiro',
    is_final: false,
    color: 'info'
  },
  'withdrawaled': {
    label: 'Retirado',
    is_final: true,
    color: 'success'
  }
};
